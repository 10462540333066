<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <div class="content">
        <el-tabs model-value="first" class="demo-tabs">
          <el-tab-pane label="球员数据" name="first">
            <el-table :data="sportsman_list" style="width: 100%">
              <el-table-column prop="cover_info.english_name" label="球员名" width="250" />
              <el-table-column prop="create_time" label="创建时间" width="180" />
              <el-table-column prop="game_version" label="游戏版本" width="180" />
              <el-table-column prop="status" label="状态" width="180">
                <template #default="scope">
                  <span v-if="scope.row.status === 0">草稿</span>
                  <span v-else-if="scope.row.status === 1">待审批</span>
                  <span v-else-if="scope.row.status === 2">审核通过(已发布)</span>
                  <span v-else-if="scope.row.status === 3">审核不通过</span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作">
                <template #default="scope">
                  <el-popconfirm title="您确定要编辑球员数据吗?" confirm-button-text="是" cancel-button-text="否" @confirm="jump_page('/sportsman_detail_edit', {Id: scope.row._id})">
                    <template #reference>
                      <el-button type="primary" size="small" v-if="scope.row.status === 0">编辑</el-button>
                    </template>
                  </el-popconfirm>
                  <el-popconfirm title="您确定要将此球员数据提交至审核吗?" confirm-button-text="是" cancel-button-text="否" @confirm="my_sportsman_audit(scope.row._id)">
                    <template #reference>
                      <el-button type="success" size="small" v-if="scope.row.status === 0">提交审核</el-button>
                    </template>
                  </el-popconfirm>
                  <el-popconfirm title="您确定要删除此球员数据吗?" confirm-button-text="是" cancel-button-text="否" @confirm="my_sportsman_delete(scope.row._id)">
                    <template #reference>
                      <el-button type="danger" size="small" v-if="scope.row.status === 0">删除</el-button>
                    </template>
                  </el-popconfirm>
                  <el-button type="info" size="small" v-if="scope.row.status !== 0">无法操作</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="综合数据">
            <el-table :data="article_list" style="width: 100%">
              <el-table-column prop="cover_info.cover_title" label="文章名" width="400" />
              <el-table-column prop="type" label="类型" width="80">
                <template #default="scope">
                  <span v-if="scope.row.type === '0'">球队</span>
                  <span v-else-if="scope.row.type === '1'">最佳</span>
                  <span v-else-if="scope.row.type === '2'">数据</span>
                  <span v-else-if="scope.row.type === '3'">皮肤</span>
                  <span v-else-if="scope.row.type === '4'">战术</span>
                  <span v-else-if="scope.row.type === '5'">视频</span>
                  <span v-else-if="scope.row.type === '6'">文章</span>
                </template>
              </el-table-column>
              <el-table-column prop="create_time" label="创建时间" width="180" />
              <el-table-column prop="status" label="状态" width="150">
                <template #default="scope">
                  <span v-if="scope.row.status === 0">草稿</span>
                  <span v-else-if="scope.row.status === 1">待审批</span>
                  <span v-else-if="scope.row.status === 2">审核通过(已发布)</span>
                  <span v-else-if="scope.row.status === 3">审核不通过</span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作">
                <template #default="scope">
                  <el-popconfirm title="您确定要编辑文章数据吗?" confirm-button-text="是" cancel-button-text="否" @confirm="jump_page('/complex_edit', {Id: scope.row._id})">
                    <template #reference>
                      <el-button type="primary" size="small" v-if="scope.row.status === 0">编辑</el-button>
                    </template>
                  </el-popconfirm>
                  <el-popconfirm title="您确定要将此文章数据提交至审核吗?" confirm-button-text="是" cancel-button-text="否" @confirm="my_article_audit(scope.row._id)">
                    <template #reference>
                      <el-button type="success" size="small" v-if="scope.row.status === 0">提交审核</el-button>
                    </template>
                  </el-popconfirm>
                  <el-popconfirm title="您确定要删除此文章数据吗?" confirm-button-text="是" cancel-button-text="否" @confirm="my_article_delete(scope.row._id)">
                    <template #reference>
                      <el-button type="danger" size="small" v-if="scope.row.status === 0">删除</el-button>
                    </template>
                  </el-popconfirm>
                  <el-button type="info" size="small" v-if="scope.row.status !== 0">无法操作</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {get_my_data_sportsman, post_my_data_sportsman_audit, post_my_data_sportsman_delete} from "../../api/api";
import {get_my_data_article, post_my_data_article_audit, post_my_data_article_delete} from "../../api/api";
import {jump_page, success_hint} from "../../tool/tools";

export default {
  name: "MyData",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  },
  data(){
    return {
      sportsman_list: [],
      article_list: []
    }
  },
  mounted() {
    this.obtain_my_sportsman();
    this.obtain_my_article();
  },
  methods: {
    jump_page,
    obtain_my_sportsman(){
      get_my_data_sportsman().then(response => {
        if(response.Code === 1){
          this.sportsman_list = response.Data;
        }
      });
    },
    obtain_my_article(){
      get_my_data_article().then(response => {
        if(response.Code === 1){
          this.article_list = response.Data;
        }
      });
    },
    my_sportsman_audit(id){
      post_my_data_sportsman_audit({
        sportsman_id: id
      }).then(response => {
        if(response.Code === 1){
          success_hint("已提交至审核");
          this.obtain_my_sportsman();
        }
      });
    },
    my_sportsman_delete(id){
      post_my_data_sportsman_delete({
        sportsman_id: id
      }).then(response => {
        if(response.Code === 1){
          success_hint("已删除");
          this.obtain_my_sportsman();
        }
      });
    },
    my_article_audit(id){
      post_my_data_article_audit({
        article_id: id
      }).then(response => {
        if(response.Code === 1){
          success_hint("已提交至审核");
          this.obtain_my_article();
        }
      });
    },
    my_article_delete(id){
      post_my_data_article_delete({
        article_id: id
      }).then(response => {
        if(response.Code === 1){
          success_hint("已删除");
          this.obtain_my_article();
        }
      });
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
@import "../../static/universal/index.css";

.content{
  background: white;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  text-align: center;
}
.content >>> li{
  height: 100px;
  background: yellow;
  margin-bottom: 15px;
}
</style>